.errorMessageBG {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  width: 95%;
  margin-bottom: 10px;
}
.errorMessageTxt {
  color: red;
  text-align: center;
}
