@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.changing-text-color {
  margin-top: 5px;
  color: darkgreen;
  animation: blink 1s linear infinite;
}
