.heading {
  margin-top: 40px;
  text-align: center;
}

.container {
  display: flex;
  margin-bottom: 30px;
}

.keyFeaturesContainer {
  display: flex;
  justify-content: center;
  padding: 40px;
}

.container-how-works {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding: 40px;
}

.content {
  font-size: 22px;
  white-space: pre-wrap;
}
