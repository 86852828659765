.container {
  border-radius: 2px;
  border: 1px;
  border-color: darkgray;
  height: 74vh;
  width: 100%;
  border-style: solid;
  background-color: rgb(255, 255, 250);
  overflow: auto;
  margin-top: 44px;
}

.CopyToClipboardTxt {
  text-align: right;
  color: #0645ad;
  cursor: pointer;
}

.invalidJson {
  padding: 10px;
  font-size: medium;
  font-family: monospace;
  color: crimson;
  white-space: pre-wrap;
}

.keyValues {
  font-family: monospace;
  font-style: initial;
}

.invalidValues {
  font-family: monospace;
  color: aliceblue;
  background-color: brown;
  font-style: initial;
}

.SyntaxError {
  color: darkred;
}

.boolean {
  color: #0747a6;
}
.number {
  color: #ad7fa8;
}
.string {
  color: #4e9a06;
}

.dataTypeLabel {
  color: red !important;
}
