.input {
  margin-top: 29px;
  font-size: 15px;
  line-height: 2.5 !important;
  padding-left: 20px !important;
}
.buttonRightLink {
  margin-bottom: 25px;
  margin-top: 25px;
  justify-content: center;
  display: flex;
}
.textarea {
  height: 74vh;
  resize: none;
  width: 100%;
  font-size: large;
  outline: none !important;
  border: 1px solid gray;
  border-radius: 10px;
  font-family: monospace;
}
