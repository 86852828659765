.container {
  border-radius: 10px;
  border: 1px;
  height: 74vh;
  width: 100%;
  border-color: darkgray;
  border-style: solid;
  background-color: white;
  overflow: auto;
}

.content {
  word-wrap: break-word;
  padding: 10px;
  font-size: large;
  font-family: monospace;
  white-space: pre-wrap;
}

.error-content {
  word-wrap: break-word;
  padding: 10px;
  font-size: medium;
  font-family: monospace;
  white-space: pre-wrap;
  color: crimson;
  white-space: pre-wrap;
}
