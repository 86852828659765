.container {
  border: 2px solid #ccc;
  margin-top: 25px;
  border-radius: 15px;
}

.input {
  width: 100%;
  height: 40px;
}
